<template>
  <div class="pa-5">
    <div class="page-title-box d-flex align-items-center justify-content-between">
      <h4 class="mb-0">Dashboard</h4>
    </div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="8">
        <v-row class="pa-0">
          <v-col cols="12" sm="12" md="4" lg="4">
            <v-card
              elevation="1"
              class="pa-3 box-dashboard-data d-flex flex-column justify-space-between"
            >
              <div class="d-flex flex-row align-center justify-space-between">
                <div class="d-flex flex-column">
                  <span class="title-data-dashboard">
                    ฿74,152
                  </span>
                  <span class="label-data-dashboard">ยอดขายวันนี้</span>
                </div>
                <v-icon color="rgba(91, 115, 232, 0.85)">mdi-chart-bar</v-icon>
              </div>

              <div class="d-flex flex-row align-center mt-3">
                <v-icon color="#34c38f">mdi-arrow-up-bold</v-icon>
                <span class="label-data-dashboard" style="color: #34c38f!important">2.65%</span>
                <span class="ml-3 label-data-dashboard">จากเมื่อวาน</span>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="4" lg="4">
            <v-card
              elevation="1"
              class="pa-3 box-dashboard-data d-flex flex-column justify-space-between"
            >
              <div class="d-flex flex-row align-center justify-space-between">
                <div class="d-flex flex-column">
                  <span class="title-data-dashboard">
                    ฿564,350
                  </span>
                  <span class="label-data-dashboard">ยอดขายเดือนนี้</span>
                </div>
              </div>

              <div class="d-flex flex-row align-center mt-3">
                <v-icon color="#34c38f">mdi-arrow-up-bold</v-icon>
                <span class="label-data-dashboard" style="color: #34c38f !important">10.51%</span>
                <span class="ml-3 label-data-dashboard">จากเดือนที่แล้ว</span>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="4" lg="4">
            <v-card
              elevation="1"
              class="pa-3 box-dashboard-data d-flex flex-column justify-space-between"
            >
              <div class="d-flex flex-row align-center justify-space-between">
                <div class="d-flex flex-column">
                  <span class="title-data-dashboard">
                    ฿89,264,350
                  </span>
                  <span class="label-data-dashboard">ยอดขายปีนี้</span>
                </div>
              </div>

              <div class="d-flex flex-row align-center mt-3">
                <v-icon color="#34c38f">mdi-arrow-up-bold</v-icon>
                <span class="label-data-dashboard" style="color: #34c38f !important">10.51%</span>
                <span class="ml-3 label-data-dashboard">จากปีที่แล้ว</span>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="12">
            <v-card
              elevation="1"
              class="pa-3 box-dashboard-data d-flex flex-column justify-space-between"
            >
            <div class="d-flex flex-row justify-space-between align-center">
              <strong>รายชื่อผู้รอรับบริการ<span class="ml-3">8/15</span></strong>
              <v-btn text color="primary">เพิ่มเติม >>></v-btn>
            </div>
            <v-data-table
              :headers="headers"
              :items="dataListCustomer"
              :search="filter_search"
              :loading="loaddata"
              loading-text="กำลังโหลด..."
            >
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-start">{{item.customer_code}}</td>
                <td class="text-start">{{`คุณ${item.customer_fnameth} ${item.customer_lnameth}`}}</td>
                <td class="text-center">{{moment(item.customer_birthdate, "YYYYMMDD").locale('th').fromNow().replace(' ปีที่แล้ว', '')}}</td>
                <td class="text-start">{{`${item.customer_activity} ${item.customer_activity_list}`}}</td>
                <td class="text-end">{{item.customer_prepaid}}</td>
                <td class="text-end">{{item.customer_activity_list_cost - item.customer_prepaid}}</td>
                <td class="text-center">
                  <v-icon
                    :color="item.customer_activity === 'สนใจ' ? 'red' : item.customer_activity === 'ทำ' ? 'pink' : 'purple'"
                  >mdi-circle
                  </v-icon>
                </td>
              </tr>
            </template>

            <template v-slot:no-data>
              <v-btn
                color="primary"
                @click="initData"
              >
                รีเซ็ต
              </v-btn>
            </template>
            </v-data-table>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="12">
            <v-card
              elevation="1"
              class="pa-3 box-dashboard-data d-flex flex-column justify-space-between"
            >
            <div class="d-flex flex-row justify-space-between align-center">
              <strong>รายชื่อผู้รอปรึกษาแพทย์</strong>
              <v-btn text color="primary">เพิ่มเติม >>></v-btn>
            </div>
            <v-data-table
              :headers="headers"
              :items="dataListCustomer"
              :search="filter_search"
              :loading="loaddata"
              loading-text="กำลังโหลด..."
            >
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-start">{{item.customer_code}}</td>
                <td class="text-start">{{`คุณ${item.customer_fnameth} ${item.customer_lnameth}`}}</td>
                <td class="text-center">{{moment(item.customer_birthdate, "YYYYMMDD").locale('th').fromNow().replace(' ปีที่แล้ว', '')}}</td>
                <td class="text-start">{{`${item.customer_activity} ${item.customer_activity_list}`}}</td>
                <td class="text-end">{{item.customer_prepaid}}</td>
                <td class="text-end">{{item.customer_activity_list_cost - item.customer_prepaid}}</td>
                <td class="text-center">
                  <v-icon
                    :color="item.customer_activity === 'สนใจ' ? 'red' : item.customer_activity === 'ทำ' ? 'pink' : 'purple'"
                  >mdi-circle
                  </v-icon>
                </td>
              </tr>
            </template>

            <template v-slot:no-data>
              <v-btn
                color="primary"
                @click="initData"
              >
                รีเซ็ต
              </v-btn>
            </template>
            </v-data-table>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="12">
            <v-card
              elevation="1"
              class="pa-3 box-dashboard-data d-flex flex-column justify-space-between"
            >
            <div class="d-flex flex-row justify-space-between align-center">
              <strong>รายชื่อผู้รอทำหัตถการ</strong>
              <v-btn text color="primary">เพิ่มเติม >>></v-btn>
            </div>
            <v-data-table
              :headers="headers"
              :items="dataListCustomer"
              :search="filter_search"
              :loading="loaddata"
              loading-text="กำลังโหลด..."
            >
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-start">{{item.customer_code}}</td>
                <td class="text-start">{{`คุณ${item.customer_fnameth} ${item.customer_lnameth}`}}</td>
                <td class="text-center">{{moment(item.customer_birthdate, "YYYYMMDD").locale('th').fromNow().replace(' ปีที่แล้ว', '')}}</td>
                <td class="text-start">{{`${item.customer_activity} ${item.customer_activity_list}`}}</td>
                <td class="text-end">{{item.customer_prepaid}}</td>
                <td class="text-end">{{item.customer_activity_list_cost - item.customer_prepaid}}</td>
                <td class="text-center">
                  <v-icon
                    :color="item.customer_activity === 'สนใจ' ? 'red' : item.customer_activity === 'ทำ' ? 'pink' : 'purple'"
                  >mdi-circle
                  </v-icon>
                </td>
              </tr>
            </template>

            <template v-slot:no-data>
              <v-btn
                color="primary"
                @click="initData"
              >
                รีเซ็ต
              </v-btn>
            </template>
            </v-data-table>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="12">
            <v-card
              elevation="1"
              class="pa-3 box-dashboard-data d-flex flex-column justify-space-between"
            >
            <div class="d-flex flex-row justify-space-between align-center">
              <strong>รายชื่อผู้ที่ใช้บริการเรียบร้อยแล้ว</strong>
              <v-btn text color="primary">เพิ่มเติม >>></v-btn>
            </div>
            <v-data-table
              :headers="headers"
              :items="dataListCustomer"
              :search="filter_search"
              :loading="loaddata"
              loading-text="กำลังโหลด..."
            >
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-start">{{item.customer_code}}</td>
                <td class="text-start">{{`คุณ${item.customer_fnameth} ${item.customer_lnameth}`}}</td>
                <td class="text-center">{{moment(item.customer_birthdate, "YYYYMMDD").locale('th').fromNow().replace(' ปีที่แล้ว', '')}}</td>
                <td class="text-start">{{`${item.customer_activity} ${item.customer_activity_list}`}}</td>
                <td class="text-end">{{item.customer_prepaid}}</td>
                <td class="text-end">{{item.customer_activity_list_cost - item.customer_prepaid}}</td>
                <td class="text-center">
                  <v-icon
                    :color="item.customer_activity === 'สนใจ' ? 'red' : item.customer_activity === 'ทำ' ? 'pink' : 'purple'"
                  >mdi-circle
                  </v-icon>
                </td>
              </tr>
            </template>

            <template v-slot:no-data>
              <v-btn
                color="primary"
                @click="initData"
              >
                รีเซ็ต
              </v-btn>
            </template>
            </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="4">
        <v-card
          elevation="1"
          class="pa-3 box-dashboard-data d-flex flex-column justify-start"
        >
          <div class="d-flex flex-row align-center justify-space-between">
            <span class="top-title-data-dashboard">กราฟเปรียบเทียบยอดขาย</span>
            <!-- <div class="d-flex flex-row align-center">
              <span class="top-title-data-dashboard">ประเภท:</span>
              <v-select
                :items="itemsDMY"
                v-model="selectDMY"
                label="ประเภท"
                dense
                solo
                class="ml-3"
                single-line
                hide-details
                style="max-width: 8rem !important;"
              ></v-select>
            </div> -->
          </div>

          <highcharts :options="chartDailyOptions" class="mt-3" style="width: 100%; overflow: auto !important;"></highcharts>
          <highcharts :options="chartMonthlyOptions" style="width: 100%; overflow: auto !important;"></highcharts>
          <highcharts :options="chartYearlyOptions" class="mt-3" style="width: 100%; overflow: auto !important;"></highcharts>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'

export default {
  components: {
    highcharts: Chart
  },
  data () {
    return {
      itemsDMY: ['รายวัน', 'รายเดือน', 'รายปี'],
      selectDMY: 'รายเดือน',
      itemsWMY: ['รายสัปดาห์', 'รายเดือน', 'รายปี'],
      selectWMY: 'รายปี',
      valueWHY: 60,
      donutChartCustomer: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false
        },
        title: false,
        tooltip: false,
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              distance: -50,
              style: {
                fontWeight: 'bold',
                color: 'white'
              }
            },
            startAngle: 0,
            endAngle: 360,
            center: ['50%', '75%'],
            size: '110%'
          }
        },
        series: [{
          type: 'pie',
          name: 'จำนวนลูกค้า',
          innerSize: '50%',
          data: [{
            name: 'ลูกค้าเมื่อวาน',
            y: 14,
            dataLabels: {
              enabled: false
            },
            color: 'rgba(242, 242, 242, 0.85)'
          }, {
            name: 'ลูกค้าวันนี้',
            y: 18,
            dataLabels: {
              enabled: false
            },
            color: 'rgba(91, 115, 232, 0.85)'
          }]
        }],
        credits: {
          enabled: false
        }
      },
      donutDailyChartCustomer: {
        chart: {
          type: 'column'
        },
        title: false,
        xAxis: false,
        yAxis: false,
        tooltip: false,
        series: [{
          name: 'เดือนมกราคม',
          color: 'rgba(91, 115, 232, 0.85)',
          data: [90, 80, 110, 70, 60, 120, 50, 60, 80, 90, 70, 110, 90, 80, 110, 70, 60, 120, 50, 60, 80, 90, 60, 80, 90, 70, 110, 90, 80, 110]
        }],
        credits: {
          enabled: false
        }
      },
      chartDailyOptions: {
        chart: {
          type: 'column'
        },
        title: {
          text: 'กราฟเปรียบเทียบยอดขายรายวัน'
        },
        xAxis: {
          categories: [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
            31
          ]
        },
        yAxis: {
          min: 0,
          title: {
            text: 'หน่วยนับ : 1,000.00 บาท'
          },
          scrollbar: {
            enabled: true,
            liveRedraw: false
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">วันที่ {point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f} บาท</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        series: [{
          name: 'เดือนมกราคม',
          color: 'rgba(91, 115, 232, 0.85)',
          data: [90, 80, 110, 70, 60, 120, 50, 60, 80, 90, 70, 110, 90, 80, 110, 70, 60, 120, 50, 60, 80, 90, 60, 80, 90, 70, 110, 90, 80, 110]
        }],
        credits: {
          enabled: false
        }
      },
      chartMonthlyOptions: {
        chart: {
          type: 'column'
        },
        title: {
          text: 'กราฟเปรียบเทียบยอดขายรายเดือน'
        },
        xAxis: {
          categories: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.']
        },
        yAxis: {
          min: 0,
          title: {
            text: 'หน่วยนับ : 10,000.00 บาท'
          },
          scrollbar: {
            enabled: true,
            liveRedraw: false
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">วันที่ {point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f} บาท</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        series: [{
          name: 'ปีพ.ศ. 2563',
          color: 'rgba(91, 115, 232, 0.85)',
          data: [90, 80, 110, 70, 60, 120, 50, 60, 80, 90, 70, 110]
        }],
        credits: {
          enabled: false
        }
      },
      chartYearlyOptions: {
        chart: {
          type: 'column'
        },
        title: {
          text: 'กราฟเปรียบเทียบยอดขายรายปี'
        },
        xAxis: {
          categories: [2554, 2555, 2556, 2557, 2558, 2559, 2560, 2561, 2562, 2563]
        },
        yAxis: {
          min: 0,
          title: {
            text: 'หน่วยนับ : 10,000.00 บาท'
          },
          scrollbar: {
            enabled: true,
            liveRedraw: false
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">วันที่ {point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f} บาท</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        series: [{
          name: 'ยอดขายของแต่ละปีที่ผ่านมา',
          color: 'rgba(91, 115, 232, 0.85)',
          data: [220, 180, 110, 200, 250, 200, 190, 220, 230, 230]
        }],
        credits: {
          enabled: false
        }
      },
      loaddata: false,
      filter_search: '',
      dataListCustomer: [{
        _id: '0000001',
        customer_code: 'WG640015',
        customer_fnameth: 'ชูใจ',
        customer_lnameth: 'นามสมมติ',
        customer_fnameen: 'Chojai',
        customer_lnameen: 'Namsommud',
        customer_nicknameth: 'กุ๊ก',
        customer_nicknameen: 'Kook',
        customer_birthdate: '1990-01-12',
        customer_activity: 'สนใจ',
        customer_activity_list: 'Botox กราม',
        customer_prepaid: 1000,
        customer_activity_list_cost: 3999
      }, {
        _id: '0000002',
        customer_code: 'WG640020',
        customer_fnameth: 'สมใจ',
        customer_lnameth: 'ใจดี',
        customer_fnameen: 'Somjai',
        customer_lnameen: 'Jaidee',
        customer_nicknameth: 'หญิง',
        customer_nicknameen: 'Ying',
        customer_birthdate: '1960-04-19',
        customer_activity: 'นัดติดตามผล',
        customer_activity_list: 'Filter ร่องแก้ม',
        customer_prepaid: 4999,
        customer_activity_list_cost: 4999
      }, {
        _id: '0000003',
        customer_code: 'WG640035',
        customer_fnameth: 'สมาน',
        customer_lnameth: 'ภักดี',
        customer_fnameen: 'Saman',
        customer_lnameen: 'Phakdee',
        customer_nicknameth: 'สมาน',
        customer_nicknameen: 'Saman',
        customer_birthdate: '1944-07-21',
        customer_activity: 'ทำ',
        customer_activity_list: 'Drip 1 ครั้งที่ 2/5',
        customer_prepaid: 10000,
        customer_activity_list_cost: 10000
      }]
    }
  },
  computed: {
    headers () {
      return [
        {
          text: 'รหัส',
          align: 'center',
          value: 'customer_code',
          sortable: false
        }, {
          text: 'ชื่อ - นามสกุล',
          align: 'center',
          value: 'customer_fnameth',
          sortable: false
        }, {
          text: 'อายุ',
          align: 'center',
          value: 'customer_birthdate',
          sortable: false
        }, {
          text: 'รายการ',
          align: 'center',
          value: 'customer_activity',
          sortable: false
        }, {
          text: 'จ่ายแล้ว',
          align: 'center',
          value: 'customer_prepaid',
          sortable: false
        }, {
          text: 'คงเหลือ',
          align: 'center',
          value: 'customer_activity_list_cost',
          sortable: false
        }, {
          text: '',
          align: 'center',
          value: 'customer_activity',
          sortable: false
        }
      ]
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      const self = this
      self.loaddata = true
      setTimeout(() => {
        self.loaddata = false
      }, 2000)
    }
  }
}
</script>

<style scoped>
@import '../../styles/Dashboard.css';
</style>
